import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import './components.css';
import { CardContainerComponents } from '../../Components/CardContainer.js';
import '../../Components/cardContainer.css'

function ComponentsPage({textDataArray, displayLang}){
    const tt = textDataArray._components;
    const ttNav = textDataArray._navBar;

    return(
        <main>
            <div id='componentsPage'>
                <header>
                <img className='sectionTitle' src ={require('../../images/components_main_2.jpg')} />
                <div className='cardsSection'>
                    <h1>{tt.headerH1[displayLang]}</h1>
                    <h2>{tt.headerH2[displayLang]}</h2>
                    <p>{tt.headerP[displayLang]}</p>
                </div>
                </header>

                    <div id="mainCardContainer">
                        <div id='cardContainer'>
                            {/* gardé hard-codé puisque ne changera jamais*/}
                            <article>
                                <div className="card GatewayComponentsPage">
                                    <img src={require('../../images/woodEssence_main_3.jpg')}  alt="keyboard1"/>
                                    <ul><li>{ttNav.subPWE[displayLang]}</li></ul>
                                    <NavLink className="cardNavLink" to="/components/wood" title={ttNav.subPWE[displayLang]}></NavLink>
                                </div>
                            </article>
                            <article>
                            <div className="card GatewayComponentsPage">
                                <img src={require('../../images/PCB_main.jpg')}  alt="keyboard1"/>
                                <ul><li>{ttNav.subPPCB[displayLang]}</li></ul>
                                <NavLink className="cardNavLink" to="/components/pcb" title={ttNav.subPPCB[displayLang]}></NavLink>
                            </div>
                            </article>
                            <article>
                            <div className="card GatewayComponentsPage">
                                <img src={require('../../images/plate_main.jpg')}  alt="keyboard1"/>
                                <ul><li> {ttNav.subPPlate[displayLang]} </li></ul>
                                <NavLink className="cardNavLink" to="/components/plate" title={ttNav.subPPlate[displayLang]}></NavLink>
                            </div>
                            </article>
                            <article>
                            <div className="card GatewayComponentsPage">
                                <img src={require('../../images/Switches_main_4.jpg')}  alt="keyboard1"/>
                                <ul><li> {ttNav.subPSwitches[displayLang]} </li></ul>
                                <NavLink className="cardNavLink" to="/components/switches" title={ttNav.subPSwitches[displayLang]} ></NavLink>
                            </div>
                            </article>
                            <article>
                            <div className="card GatewayComponentsPage">
                                <img src={require('../../images/K000_6.jpg')}  alt="keyboard1"/>
                                <ul><li> {ttNav.subPKeycaps[displayLang]} </li></ul>
                                <NavLink className="cardNavLink" to="/components/keycaps" title={ttNav.subPKeycaps[displayLang]} ></NavLink>
                            </div>
                            </article>
                            <article>
                            <div className="card GatewayComponentsPage">
                                <img src={require('../../images/PCB_plate1.jpg')}  alt="keyboard1"/>
                                <ul><li> {ttNav.subPStabs[displayLang]} </li></ul>
                                <NavLink className="cardNavLink" to="/components/stabs" title={ttNav.subPStabs[displayLang]} ></NavLink>
                            </div>
                            </article>
                            <article>
                            <div className="card GatewayComponentsPage">
                                <img src={require('../../images/cable_main.jpg')}  alt="keyboard1"/>
                                <ul><li> {ttNav.subPCables[displayLang]} </li></ul>
                                <NavLink className="cardNavLink" to="/components/cable" title={ttNav.subPCables[displayLang]} ></NavLink>
                            </div>
                            </article>
                        </div>     
                    </div>
            </div>
        </main>
    )
}

function ComponentsDetailsPage(props){
    console.log("dans component details page, props dataArray:" + JSON.stringify(props.dataArray));

    return(
        <main>
            <div id='componentsPage'>
                <header>
                    <img className='sectionTitle' id={props.id} src ={props.backgroundPicture} />
                    {/* <div className='bgBlurr'> </div> */}
                    <div className='cardsSection'>
                        <h1>{props.title}</h1>
                        <h2>{props.subTitle}</h2>
                        <p>{props.text}
                        </p>
                    </div>
                </header>
                
                <CardContainerComponents 
                    dataArray={props.dataArray} 
                    addToCart={props.addToCart}
                    textDataArray={props.textDataArray}
                    displayLang={props.displayLang}
                    displayCurr={props.displayCurr}
                    />
            </div>
        </main>
    )
}


export {ComponentsPage, ComponentsDetailsPage}