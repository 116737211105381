import React, { useState, useEffect } from 'react';
import './aboutUs.css';

const AboutUsPage=({textDataArray, displayLang})=>{

    const tt = textDataArray._aboutUs;
    
    return(
        <main>
            <div id='AUPage'>
                <header>
                    <div className='sectionTitle'> {/*Title*/}
                        <h1>{tt.titleH1[displayLang]}</h1>
                        <h2>{tt.titleH2[displayLang]}</h2>
                    </div>
                </header>
                <article>
                    <div className='innerText'> {/*First box*/}
                        <p>
                        {tt.titleP[displayLang]}
                        </p>
                    </div>
                </article>
                <section>  {/* Why Hide Nothing*/}
                    <div className='section' id='firstSection'>
                        <header>
                            <h1>{tt.firstSectionH1[displayLang]}</h1>
                        </header>
                        <p>{tt.firstSectionP[displayLang]}</p>
                    </div>
                </section>

                <section> {/* Vision */}
                    <div className=' section AUCardMainContainer'>
                        <header>
                            <h1>{tt.visionH1[displayLang]}</h1>
                        </header>
                        <div className='AUCardContainer'>
                            <div className='AUCard'>
                                <img src={require('../../images/K0003_4.jpg')} alt="Padauk keyboard backplate"/>
                                <div className='cardText'>
                                    <p>{tt.visionP[displayLang]}</p>
                                    <div className='actButton'>
                                        <div className='btn' >{tt.visionBtn[displayLang]}</div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section> {/* Mission */}
                    <div className=' section AUCardMainContainer'>
                        <header>
                            <h1>{tt.missionH1[displayLang]}</h1>
                        </header>
                            <div className='AUCardContainer'>
                                <div className='AUCard'>
                                    <img src={require('../../images/K0002_4.jpg')} alt="Mahogany keyboard backplate"/>
                                    <div className='cardText'>
                                        <p>{tt.missionP[displayLang]}</p>
                                        <div className='actButton'>
                                            <div className='btn' >{tt.missionBtn[displayLang]}</div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                    </div>
                </section>

                <section> {/* Mission */}
                    <div className='section'>
                        <header>
                            <h1>{tt.uniqueH1[displayLang]} </h1>
                        </header>
                        <p>{tt.uniqueP[displayLang]}</p>
                    </div>
                </section>

                <section> {/* Simple keyboard picture */}
                    <div className='section bigPict'>
                        <img src={require('../../images/inStock_main.jpg')} alt="65% keyboard with a dark walnut backplate and white and blue keycaps"/>
                        
                    </div>
                </section>
                <section> {/* World of possibilities*/}
                    <div className='section lastSection' >
                        <header>
                            <h1>{tt.lastSectionH1[displayLang]} </h1>
                        </header>
                        <p>{tt.lastSectionP[displayLang]} </p>
                    </div>
                </section>
            </div>
        </main>
    )
}

export{AboutUsPage};
