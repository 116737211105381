import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import './footer.css';


function Footer({textDataArray, displayLang}){
    const tt = textDataArray._footer;

    
    return(
        <footer>
            <div className='footerContainer'>
                <div className='footerSection'>
                    <NavLink to="/" className='navlink'> 
                    <img src={require("../images/logoFinal_small_white.png")} alt="HNK"/>
                    </NavLink>
                    <p>{tt.firstP[displayLang]}</p>
                    <p>{tt.secondP[displayLang]}</p>
                </div>
                <div className='footerSection'>
                    <h1>{tt.usefullLinksh1[displayLang]}</h1>
                    <NavLink className='navlink' to="/aboutus">{tt.ulAboutUs[displayLang]}</NavLink>
                    <NavLink className='navlink' to="/policies">{tt.ulPolicies[displayLang]}</NavLink>
                    <NavLink className='navlink' to="/ownerspage">{tt.ulOwnersPage[displayLang]}</NavLink>
                </div>
                <div className='footerSection'>
                    <h1>{tt.contactUsh1[displayLang]}</h1>
                    <p><a id='mailto' href="mailto:info@hnkbd.ca">{tt.contactUsP1[displayLang]}</a></p>
                </div>
                <div className='subFooter'>
                <p>	{tt.contactUsP2[displayLang]}</p>
            </div>
            </div>
        </footer>
    )
}

export {Footer}
