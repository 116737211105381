import React from 'react';
import ReactDOM from 'react-dom/client';
import { createContext } from "react";
import './index.css';
import {App} from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import {disableReactDevTools} from '@fvilers/disable-react-devtools'

const backendURLContext = createContext();
// const backendURL = {backend:"http://localhost:5001", frontend:"https://localhost:3000"}; //Dev
const backendURL = {backend:"https://hnkbackend.onrender.com", frontend:"https://hnkbd.ca"};  //Prod
//

if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <backendURLContext.Provider value={backendURL}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </backendURLContext.Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export {backendURLContext}

