import React, { useState, useEffect } from 'react';
import './inStock.css';
import "react-multi-carousel/lib/styles.css";
import { CardContainer } from '../../Components/CardContainer.js';

function InStockPage({dataArray, addToCart, textDataArray, displayLang, displayCurr}){
    const tt = textDataArray._inStock;

    return(
        <main>
            <div id='inStockPage'>
                <header>
                    <div className='IS_SectionTitle'>
                        <h1>{tt.headerH1[displayLang]}</h1>
                        <h2>{tt.headerH2[displayLang]}</h2>
                        <p>{tt.headerP[displayLang]}</p>
                    </div>
                </header>
                <CardContainer 
                    dataArray={dataArray} 
                    addToCart={addToCart}
                    textDataArray={textDataArray}
                    displayLang={displayLang}
                    displayCurr={displayCurr}
                    />
            </div>
        </main>
    )
}

export {InStockPage}